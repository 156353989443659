import "@fontsource-variable/open-sans";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GatsbyBrowser } from "gatsby";
import featureFlags from "./src/featureFlags";
import { posthogAnalytics } from "./src/posthogAnalytics";
import "./src/styles/global.css";

function isDoNotTrack() {
  let doNotTrack: boolean | undefined = undefined;
  if (
    (window as any).doNotTrack ||
    navigator.doNotTrack ||
    (navigator as any).msDoNotTrack
  ) {
    if (
      (window as any).doNotTrack === "1" ||
      navigator.doNotTrack === "yes" ||
      navigator.doNotTrack === "1" ||
      (navigator as any).msDoNotTrack === "1"
    ) {
      doNotTrack = true;
    } else {
      doNotTrack = false;
    }
  } else {
    doNotTrack = undefined;
  }
  return doNotTrack === undefined ? false : doNotTrack;
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (process.env.GATSBY_FIREBASE_API_KEY) {
    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    if (featureFlags.USE_GA4) {
      // Call needed to init analytics
      const analytics = getAnalytics(app);

      if (isDoNotTrack()) {
        app.automaticDataCollectionEnabled = false;
        logEvent(analytics, "do_not_track");
      }
    }
  }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  posthogAnalytics.pageView(location.pathname);
};
